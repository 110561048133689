<template>
  <div>
    <div class="form-group m-3 d-flex justify-content-between"
         v-tippy="{ placement : 'top-end',  arrow: true}"
         content="Remember to check VINs length and empty spaces">
      <b-form-file accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   v-model="file"
                   @change="readFile" ref="input"/>
    </div>
    <b-tabs lazy>
      <b-tab v-for="(page, index) of pages">
        <template #title>
          {{ page.name }}
          <b-button @click="removePage(index, page)" pill size="sm" variant="danger" :title="`Remove page`"
                    class="remove-btn">
            <i class="material-icons">clear</i>
          </b-button>
        </template>
        <div class="p-3">
          <b-row>
            <b-col>
              <div class="form-group">
                <label>type Excel row header location number</label>
                <b-form-input ref="input_row"
                              @keyup.enter="reImportPage(page, $event.target.value, index)"></b-form-input>
                <div class="text-right mt-2">
                  <b-button variant="dark" @click="reImportPage(page, $refs.input_row[0].$el.value, index)">Set
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col>
              <validation-observer ref="form">
                <form @submit.prevent="save(page, index)">
                  <div v-for="field of Object.keys(page.fields_to_save)" class="form-group">
                    <validation-provider rules="required" v-slot="validation">
                      (Select column for {{ field }})
                      <b-form-select :state="getState(validation)" class="form-control"
                                     v-model="page.fields_to_save[field]">
                        <option :value="null">Select</option>
                        <option :value="value" v-for="value of page.fields.filter(el => el !== 'actions')">
                          {{ value }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ validation.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="validation">
                      <label>Client</label>
                      <b-form-select :state="getState(validation)" v-model="page.client_id" class="form-control">
                        <option :value="null">Select a client</option>
                        <option v-for="client of clientList" :value="client.id">{{ client.fullName }}</option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ validation.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <div class="text-right">
                    <b-button @click="filter(page)" variant="dark">Filter</b-button>
                    <b-button type="submit" variant="dark">Save</b-button>
                  </div>
                </form>
              </validation-observer>
            </b-col>
          </b-row>
        </div>
        <b-table responsive bordered hover :items="page.data" :fields="page.fields" small class="datatable">
          <template #cell(actions)="item">
            <b-button variant="danger" style="opacity: .8;"
                      title="Remove row" class="remove-btn"
                      size="sm" @click="removeRow(page, item.index)"><i class="material-icons">clear</i></b-button>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import XLSX from "xlsx";
import {get, post} from "@/services/api";
import {UserRole} from "@/utils/auth.roles";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Import",
  mixins: [show_alert_mixin],
  data: () => ({
    file: null,
    pages: [],
    workbook: null,
    clientList: []
  }),
  methods: {
    async readFile(e) {
      const file = e.target.files[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.fileChosen = true;
        const reader = new FileReader();
        this.showLoader()
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          const workbook = XLSX.read(arrayBuffer, {type: 'array'});
          this.workbook = workbook;
          const data = [];
          Object.keys(workbook.Sheets).forEach(sheet => {
            const value = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
              raw: false,
              header: "A",
              blankrows: true,
            })
            let fields = [];
            value.forEach(obj => {
              fields = [...new Set([...fields, ...Object.keys(obj)])];
            })
            fields.unshift('actions');
            data.push({
              name: sheet,
              data: value,
              fields: fields,
              fields_to_save: {
                VIN: null,
                price: null,
              },
              client_id: null,
            })
          });
          this.pages = data;
          this.file = null;
          this.$swal.close();
        }
        reader.readAsArrayBuffer(file)
      } else {
        this.showAlertNotification('File not permitted', 'error')
      }
    },
    async showLoader() {
      this.$swal({
        title: 'please wait...',
        customClass: 'small-swall',
        didOpen: () => {
          this.$swal.showLoading()
        },
        background: '#c9c9c9'
      });
    },
    removePage(index) {
      this.$swal({
        title: 'Are you sure ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000'
      }).then(value => {
        if (value.isConfirmed) {
          this.pages.splice(index, 1);
        }
      })
    },
    removeRow(page, rowIndex) {
      page.data.splice(rowIndex, 1);
    },
    save(page, index) {
      this.$refs.form[0].validate().then(success => {
        if (success) {
          page = this.filter(page);
          this.$store.commit('loaderManager', true);
          post(`previous-orders/save`, {
            data: page.data,
            fields: page.fields_to_save,
            client_id: page.client_id
          }, true).then(() => {
            this.pages.splice(index, 1);
            this.showAlertNotification('Data submitted and saved successfully')
          }).catch(() => {
            this.showAlertNotification('Something went wrong. Please, verify credits as a valid number', 'error')
          })
            .finally(() => this.$store.commit('loaderManager', false));
        }
      })
    },
    filter(page) {
      const field = page.fields_to_save.VIN;
      let length = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17,18}$/;
      let space = /^[a-zA-Z0-9_]*$/;

      if (field) {
        page.data = page.data.filter(el => {
          return el[field] ? length.test(el[field].trim()) && space.test(el[field].trim()) : false
        })
      }

      return page;
    },
    reImportPage(page, range, index) {
      this.showLoader();
      let value = XLSX.utils.sheet_to_json(this.workbook.Sheets[page.name], {
        raw: false,
        range: Number(range) - 1,
        blankrows: true
      })
      let fields = [];
      value.forEach(obj => {
        fields = [...new Set([...fields, ...Object.keys(obj)])];
      });
      if (fields.some(value1 => value1.includes('.') || value1.includes('$'))) {
        fields = fields.map(el => el.replace(/[.&]/g, ''))
        value = value.map(row => {
          const newRow = {}
          Object.keys(row).forEach(key => {
            const newKey = key.replace(/[.&]/g, '');
            newRow[newKey] = row[key]
          })
          return newRow;
        })
      }
      fields.unshift('actions');
      this.$set(this.pages, index, {
        name: page.name,
        data: value,
        fields: fields,
        fields_to_save: {
          VIN: null,
          price: null,
        },
        date_format: page.date_format,
        client_id: page.client_id,
      });
      this.$swal.close();
    },
    loadClients() {
      const roles = UserRole;
      get(`user/${roles.Dealer},${roles.SubDealer},${roles.Distributor}`, null, true)
        .then(({data}) => {
          this.clientList = data;
        })
    },
    getState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.loadClients();
  }
}
</script>

<style scoped>
.remove-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  opacity: .8;
}
</style>
